angular.module('visitIstriaApp').directive('fwdFormResetter', ['$timeout', function ($timeout) {
	function link($scope, $element) {
		var formElements = $element.find('input, select').not('input[type=submit]');

		$scope.$on('fwdFormReset', function () {
			$timeout(function () {
				angular.forEach(formElements, function (element) {
					element.value = '';
				});
			}, 500);
		});

		$scope.$on('$destroy', function () {
			formElements = $element = null;
		});
	}

	return {
		restrict: 'A',
		link: link
	};
}]);