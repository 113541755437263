angular.module('visitIstriaApp').directive('fwdGmap', ['$window', '$timeout', '$log', function ($window, $timeout, $log) {
    if (!google) {
        $log.error('MISSING: <script src="http://maps.google.com/maps/api/js?sensor=false"></script>');
    }

    return {
        restrict: 'A',
        link: link
    };

    function link($scope, $element, $attr) {
        var locationCoordinates = new google.maps.LatLng($attr.latitude, $attr.longitude);

        var mapOptions = {
            zoom: 10,
            center: locationCoordinates,
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };

        var map = new google.maps.Map($element[0], mapOptions);

        var marker = new google.maps.Marker({
            position: locationCoordinates,
            map: map,
            title: $attr.fwdGmap
        });
        
        $scope.$on('reloadGmap', function () {
            redrawMap();
        });

        angular.element($window).on('resize.gmap', function () {
            redrawMap();
        });

        function redrawMap() {
            $timeout(function () {
                google.maps.event.trigger($element[0], 'resize');
                map.setCenter(locationCoordinates);
            });
        }
    }
}]);