angular.module('visitIstriaApp').directive('fwdSubmit', [function () {
    return {
        restrict: 'A',
        link: link
    };

    function link($scope, $element) {

        $element.on('change', function () {
            this.form.submit();
        });

    }
}]);