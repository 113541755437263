angular.module('visitIstriaApp').directive('policyFancyboxContent', [function () {

    return {
        restrict: 'A',
        link: link
    };

    function link($scope, $element){
        $element.find('.plan-policy-info').click(function () {
            $element.find('.policy-content').fancybox({ maxWidth: '50%' }).trigger('click');
        });
    }

}]);