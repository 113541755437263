angular.module('visitIstriaApp').directive('fwdSelect', fwdSelect);

fwdSelect.$inject = ['$window', '$timeout'];

function fwdSelect($window, $timeout) {
    return {
        restrict: 'A',
        link: link
    };

    function link($scope, $element) {
        var timer, selectStorage, windowWidth;

        $window = angular.element($window);
        selectStorage = angular.element('#search2storage');
        windowWidth = $window.width();

        $scope.$watch(function () {
            return $element.parent().is(':visible');
        }, function (visible) {
            if (visible) {
                reloadSelect();
            }
        });

        $window.on('resize.select2', function () {
            if (windowWidth !== $window.width()) {
                windowWidth = $window.width();

                if ($element.parent().is(':visible')) {
                    if (timer) $timeout.cancel(timer);

                    timer = $timeout(function () {
                        reloadSelect();
                    },  200);
                }
            }
        });

        function reloadSelect() {
            if ($element.hasClass('select2-hidden-accessible')) {
                $element.select2('destroy');
            }

            $element.select2({
                dropdownParent: selectStorage
            });
        }
    }
}