angular.module('visitIstriaApp').service('Services', ['FWD', function (FWD) {
    var Services = this;

    Services.destinations = FWD.destinations;
    Services.accommodationList = FWD.types;

    return {
        destinations: Services.destinations,
        accommodationList: Services.accommodationList
        //accommodationValues: Services.accommodationValues
    };
}]);