angular.module('visitIstriaApp').controller('WizardController', WizardController);

WizardController.$inject = ['$scope', 'FWD', '$window'];

function WizardController($scope, FWD, $window) {
    var wizard = this;

    $window = angular.element($window);

    wizard.step = 1;
    wizard.increaseStep = function (form) {
        if (form && $window.width() < 960) {
            angular.element('html, body').animate({
                scrollTop: angular.element('.panel.' + form).offset().top
            }, 400);

            return;
        }
        if (form) wizard.form = form;

        wizard.step++;
    };

    //wizard.location = '';

    wizard.resetSteps = function (step) {
        wizard.step = step || 2;
        wizard.form = null;

        // ngModels
        wizard.date = null;
        wizard.accommodationType = null;
        wizard.accommodationTypeLabel = null;
        wizard.location = null;
        wizard.locationName = null;
        wizard.personNo = null;
        wizard.nights = null;
        wizard.adults = null;
        wizard.children = null;


        $scope.$broadcast('fwdFormReset'); // event listener is defined in fwdFormResetter directive
    };


    wizard.destinations = FWD.dest;
    wizard.types = FWD.types;

    $scope.$watch(function () {
        return wizard.accommodationType;
    }, function (newValue) {
        wizard.types.forEach(function (type) {
            if (type.value === newValue) wizard.accommodationTypeLabel = type.label;
        })
    });

    $scope.$watch(function () {
        return wizard.location;
    }, function (newValue) {
        if (newValue) {
            wizard.destinations.forEach(function (destination) {
                if (newValue.indexOf('region_') >= 0) {
                    if (newValue === 'region_' + destination.id) return wizard.locationName = destination.name;
                }
                else if (newValue.indexOf('zip_') >= 0) {
                    destination.locations.forEach(function (location) {
                        if (newValue === 'zip_' + location.zip) return wizard.locationName = location.name;
                    });
                }
                else {
                    return wizard.locationName = null;
                }

            });
        }
    });
}