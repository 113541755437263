angular.module('visitIstriaApp').controller('SearchController', SearchController);

SearchController.$inject = ['localStorageService', 'FWD'];

function SearchController(localStorage, FWD) {
        var search = this;

        search.setDetailTab = function (tab) {
            localStorage.set('detailTab', tab);
        };

        search.types = FWD.types;
        search.destinations = FWD.dest;
}