angular.module('visitIstriaApp').directive('fwdCanvas', ['$window', '$timeout', function ($window, $timeout) {
    var PIXEL_RATIO = $window.devicePixelRatio || $window.screen.deviceXDPI / $window.screen.logicalXDPI || 1;

    function calculatedSize(size) {
        return size * PIXEL_RATIO;
    }

    function fillCanvas(params) {
        params.context.drawImage(params.imageObj, 0, Math.floor(calculatedSize(params.window.scrollTop() * params.parallax)), params.image.width, params.image.height);
    }

    function clearCanvas(params) {
        params.context.clearRect(0, 0, calculatedSize(params.canvas.width()), calculatedSize(params.canvas.height()));
    }

    function resizeCanvas(params) {
        if (params.image) {
            params.image.width = params.canvas.width() > 768 ?
                calculatedSize(params.canvas.width()) :
                calculatedSize(params.canvas.height() * params.image.ratio);

            params.image.height = params.canvas.width() > 768 ?
                calculatedSize(params.canvas.width()) / params.image.ratio :
                calculatedSize(params.canvas.height());
        }

        params.canvas.attr({
            'height': calculatedSize(params.canvas.height()),
            'width': calculatedSize(params.canvas.width())
        });
    }

    function link($scope, $element, $attr) {
        var params, context, _$window;

        _$window = angular.element($window);
        context = $element[0].getContext('2d');
        params = {
            canvas: $element,
            context: context,
            window: _$window,
            parallax: $attr.parallax || 0
        };

        var imageObj = new Image();

        imageObj.src = $attr.image;

        imageObj.onload = function () {
            params.imageObj = imageObj;
            params.image = {
                ratio: imageObj.width / imageObj.height
            };
            params.image.width = _$window.width() > 768 ? calculatedSize($element.width()) : calculatedSize($element.height() * params.image.ratio);
            params.image.height = _$window.width() > 768 ? calculatedSize($element.width()) / params.image.ratio : calculatedSize($element.height());

            resizeCanvas(params);
            fillCanvas(params);
            //Blur.boxBlurCanvasRGB($attr.id, 0, 0, params.image.width, params.image.height, 4, 4);

            _$window.on('scroll.canvas', function () {
                $window.requestAnimationFrame(function () {
                    if (_$window.width() < 768 ||
                        $element.is(':hidden') ||
                        $element.offset().top > _$window.scrollTop() + _$window.height() ||
                        $element.offset().top + $element.outerHeight() < _$window.scrollTop()) {
                        return;
                    }

                    clearCanvas(params);
                    fillCanvas(params);
                });
                    //Blur.boxBlurCanvasRGB($attr.id, 0, 0, params.image.width, params.image.height, 4, 4);
            }).on('resize.canvas', function () {
                resizeCanvas(params);
                fillCanvas(params);
                //Blur.boxBlurCanvasRGB($attr.id, 0, 0, params.image.width, params.image.height, 4, 4);
            });
            
            $scope.$watch($attr.step, function () {
                if ($attr.step > 1) {
                    console.log('test step');

                    $element[0].style.webkitFilter = "blur(3px)"
                    //decreaseCanvasImageQuality($element, $attr, params);
                }
            });

        };

        function decreaseCanvasImageQuality($element, $attr, params) {
            console.log($element.attr('height'));

            if ($element.attr('height') > 80 && $element.attr('width') > 80) {
                clearCanvas(params);

                $element.attr({
                    height: $element.attr('height') / 1.5,
                    width: $element.attr('width') / 1.5
                });

                params.image.height = $element.attr('height');
                params.image.width = $element.attr('width');

                fillCanvas(params);

                $timeout(function () {
                    decreaseCanvasImageQuality($element, $attr, params);
                }, 16);
            }

        }
    }

    return {
        restrict: 'A',
        link: link
    };
}]);
