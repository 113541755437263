angular.module('visitIstriaApp').directive('fwdScroll', ['$timeout', function ($timeout) {
    return {
        restrict: 'A',
        link: link
    };

    function link($scope, $element, $attr) {
        var index = $attr.fwdScroll;

        $scope.$on('panelScroll', function (event, counter) {
            if (index == counter) {

                $timeout(function () {
                    angular.element('html, body').animate({
                        scrollTop: $element.offset().top - 30 // 30 is padding-top replacement
                    }, 200);
                }, 200);
            }
        })
    }
}]);