angular.module('visitIstriaApp').filter('fwdGrammar', function () {
	return function (input) {
		if (typeof input !== 'string') return;

		switch (input) {
			case 'Rovinj':
			case 'Poreč':
			case 'Umag':
			case 'Novigrad':
			case 'Labin':
				return input + 'u';

			case 'Pula':
				return input.substring(0, input.length - 1) + 'i';

			default:
				return input;
		}
	};
});