angular.module('visitIstriaApp').directive('fwdBxSlider', [
    '$document', '$window', '$timeout', function ($document, $window, $timeout) {

        return {
            restrict: 'A',
            link: link
        };

        function link($scope, $element) {
            $window = angular.element($window);
            $document = angular.element($document);

            $document.ready(function () {
                var slider, timer;
                
                slider = activateSlider();
                
                $window.on('resize.bxSlider', function () {
                    if ($window.width() < 1600) {

                        // prevent unnecessary slider reloads
                        if (timer) $timeout.cancel(timer);

                        timer = $timeout(function () {

                            // destroy instead of reload beacause of new option values
                            slider.destroySlider();
                            slider = activateSlider();

                        }, 500);
                    }
                });

            });

            function activateSlider() {
                return $element.bxSlider({
                    mode: 'horizontal',
                    slideWidth: $element.width() / sliderCount() - 20,
                    slideMargin: 10,
                    minSlides: 1,
                    maxSlides: sliderCount(),
                    moveSlides: sliderCount(),
                    prevText: '',
                    nextText: '',
                    pager: false
                });
            }

            // because fu
            function sliderCount() {
                return $window.width() >= 1600 ? 4 : Math.floor($window.width() / 320);
            }
        }

}]);
