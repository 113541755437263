angular.module('visitIstriaApp').controller('HomeController', HomeController);

HomeController.$inject = ['$scope', 'Services', 'FWD'];

function HomeController($scope, Services, FWD) {
    var home = this;

    home.now = new Date();
    home.destinations = FWD.dest;
    home.types = FWD.types;
}