angular.module('visitIstriaApp', [
    'ngAnimate',
    'LocalStorageModule',
    'pickadate'
]);

angular.module('visitIstriaApp').config(['$interpolateProvider', 'localStorageServiceProvider', function($interpolate, localStorage) {
    $interpolate.startSymbol('{$');
    $interpolate.endSymbol('$}');
    localStorage.setPrefix('visitIstriaApp');
}]);

angular.module('visitIstriaApp').constant('FWD', {
    dest: FWD.destinations,
    types: FWD.accommodationTypes
});
