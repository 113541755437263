angular.module('visitIstriaApp').controller('DetailController', DetailController);

DetailController.$inject = ['$scope', '$timeout', 'localStorageService'];

function DetailController($scope, $timeout, localStorage) {
    var detail = this;

    detail.shownPanel = 1;
    detail.activeTab = localStorage.get('detailTab') || 1;

    if (localStorage.get('detailTab')) localStorage.remove('detailTab');

    if (detail.activeTab === 3) {
        $scope.$broadcast('reloadGmap'); // fwdGmap directive
    }

    detail.setActiveTab = function(value) {
        detail.activeTab = value;

        if (detail.activeTab === 3) {
            $scope.$broadcast('reloadGmap'); // fwdGmap directive
        }
    };

    detail.isActiveTab = function (value) {
        return detail.activeTab == value;
    };

    // TEMPORARY SCROLL ANIMATION
    angular.element('html, body').animate({
        scrollTop: angular.element('.accomodation-detail-content').offset().top
    }, 600);

}