angular.module('visitIstriaApp').directive('fwdAutocomplete', ['Services', '$timeout', function (Services, $timeout) {
    return {
        restrict: 'A',
        scope: true,
        link: link
    };

    function link($scope, $element) {
        var input = $element.find('input');
        var query;

        $scope.suggestedLocations = [];

        $scope.resetSuggestedLocations = function () {
            $scope.suggestedLocations = [];
        };

        function queryLocation() {
            $scope.locationQuerying = true;
            query = $timeout(function () {
                //$scope.suggestedLocations = [];
                listLocations(input, $scope.suggestedLocations);
                $scope.locationQuerying = false;
            }, 300);
        }

        input.on('keyup.autocomplete', function () {
            if (query) {
                $timeout.cancel(query);
            }

            //$scope.locationQuerying = false;
            //$scope.suggestedLocations = [];

            if (input.val()) {
                queryLocation();
            }
        });

        $scope.$on('$destroy', function () {
            input.off('keyup.autocomplete');
            input = $element = query = null;
        });
    }

    function listLocations(element, array) {
        Services.locationList.forEach(function (location) {
            //destination.locations.forEach(function (location) {
                var value = '^' + element.val().toLowerCase();

                if (location.name.toLowerCase().match(new RegExp(value))) {
                    array.push(location);
                }
            //});
        });

        console.log(array);
    }
}]);