angular.module('visitIstriaApp').controller('MainController', MainController);

MainController.$inject = ['$scope'];

function MainController($scope) {
    var main = this;

    $scope.now = new Date();

    $scope.fromNow = function (days) {
        return $scope.now.getDate() + days;
    };

    $scope.range = function (n) {
        var result = [];

        for (var i = 0; i < parseInt(n); i++) {
            result.push(i);
        }

        return result;
    };

    $scope.dateFormat = function (time) {
        var test = new Date(time);

        console.log(test);
        return test;
    };

    main.hideToggles = function () {
        main.searchFormVisible = null;
        main.formTypesActive = null;
        main.mobileMenu = null;
    };
}