angular.module('visitIstriaApp').directive('iframe', iframe);

iframe.$inject = ['$window', '$timeout'];

function iframe($window, $timeout) {
    return {
        restrict: 'A',
        link: link
    };

    function link($scope, $element, $attr) {
        var _$window = angular.element($window);
        var src = {};

        src.desktop = $element.attr('src');
        src.mobile = src.desktop + $attr.iframe;

        var linkerParam = '';

        getGA(function (ga) {
            if (ga) linkerParam = '&' + ga.getAll()[0].get('linkerParam');

            if (isMobile()) {
                $element.attr('src', src.mobile + linkerParam);
                mobileEvents();
            }
            else {
                $element.attr('src', src.desktop + linkerParam);
                desktopEvents();
            }
        });

        function getGA(callback) {
            var counter = 0;

            if ($window.ga && $window.ga.getAll) {
                callback($window.ga);
            }
            else if (counter > 20) {
                console.warn('Google analytics timed out');
                callback();
            }
            else setTimeout(function () {
                getGA(callback);
            }, 100);

        }

        function isMobile() {
            return _$window.width() < 935;
        }

        function mobileEvents() {
            _$window.on('resize.iframe', function () {
                if (!isMobile()) {
                    _$window.off('resize.iframe');
                    $element.attr('src', src.desktop + linkerParam);

                    desktopEvents();
                }
            });
        }
        function desktopEvents() {
            _$window.on('resize.iframe', function () {
                if (isMobile()) {
                    _$window.off('resize.iframe');
                    $element.attr('src', src.mobile + linkerParam);

                    mobileEvents();
                }
            });
        }
    }
}
