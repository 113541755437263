angular.module('visitIstriaApp').directive('packageFancyboxContent', [function () {

    return {
        restrict: 'A',
        link: link
    };

    function link($scope, $element){
        $element.find('.plan-package-info').click(function () {
            $element.find('.package-content').fancybox({ maxWidth: '70%' }).trigger('click');
        });
    }

}]);