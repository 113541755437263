angular.module('visitIstriaApp').directive('fwdFancybox', [function () {

    return {
        restrict: 'A',
        link: link
    };

    function link($scope, $element){
        $element.find(".fancybox").fancybox();
        $element.find("i").click(function () {
           $(".fancybox").first().trigger("click");
        });
    }

}]);
