angular.module('visitIstriaApp').directive('fwdTrigger', [function () {
    return {
        restrict: 'A',
        link: link
    };

    function link($scope, $element, $attr) {
        if (typeof $attr.fwdTrigger !== 'string') $attr.fwdTrigger = $attr.fwdTrigger.toString();

        $scope.$on($attr.fwdTrigger, function () {
            $element.trigger('click');
        })
    }
}]);