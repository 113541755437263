angular.module('visitIstriaApp').directive('fwdAccordion', ['$window', '$timeout', function ($window, $timeout) {
    return {
        restrict: 'A',
        link: link
    };

    function link($scope, $element, $attr) {
        var _$window = angular.element($window);
        var $page = angular.element('html, body');
        var mobileBreakpoint = 640;

        $scope.$on('toggle-forms', function (event, type) {
            if (_$window.width() < mobileBreakpoint) {
                if (type === $attr.fwdAccordion) {
                    $element.slideToggle(200);
                    $scope.mobilePanelVisible = $scope.mobilePanelVisible === type ? null : type;

                    $timeout(function () {
                        $page.animate({
                            scrollTop: $element.parent().offset().top
                        }, 200);
                    }, 200)
                }
                else {
                    $element.slideUp(200);
                }
            }
        });

        _$window.on('resize.accordion', function () {
            if (_$window.width() >= mobileBreakpoint) {
                $element.attr('style', ''); // reset jQuery slide methods' inline CSS
            }
        });

        $scope.$on('$destroy', function () {
            _$window = $page = mobileBreakpoint = null;
            _$window.off('resize.accordion');
        });
    }
}]);